<template>
    <div>
        <header-slot />
        
        <b-nav card-header pills class="m-0">
            <b-nav-item
            to="/specialistdigital/updates/pending"
            exact
            :active="$route.meta.typeTab=='Pending'"
            :link-classes="['px-3', bgTabsNavs]"
            >
                PENDING
                <!-- <span class="ml-2">
                    <span>
                        <feather-icon icon :badge="returnedLetters" badge-classes="badge-danger badge-glow" />
                    </span>
                </span> -->
            </b-nav-item>
            
            <b-nav-item
            to="/specialistdigital/updates/completed"
            exact
            :active="$route.meta.typeTab=='Completed'"
            :link-classes="['px-3', bgTabsNavs]"
            >
                COMPLETED
                <!-- <span class="ml-2">
                    <span>
                        <feather-icon icon :badge="returnedLetters" badge-classes="badge-danger badge-glow" />
                    </span>
                </span> -->
            </b-nav-item>
            
            <b-nav-item
            to="/specialistdigital/updates/deleted"
            exact
            :active="$route.meta.typeTab=='Deleted'"
            :link-classes="['px-3', bgTabsNavs]"
            >
                DELETED
                <!-- <span class="ml-2">
                    <span>
                        <feather-icon icon :badge="returnedLetters" badge-classes="badge-danger badge-glow" />
                    </span>
                </span> -->
            </b-nav-item>
        </b-nav>
        
        <b-card no-body class="border-top-primary border-3 border-table-radius px-0">
            <router-view :key="$route.path" />
        </b-card>
        
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    components: {
        
    },
    data(){
        return{
            returnedLetters:55,
            tabActive:0,
        }
    },
    methods: {

    },
    computed:{
        
    },
    created() {
        
    },
}
</script>